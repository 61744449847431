import { createEnumParam } from 'use-query-params'

import { ReportScenario, getScenarioLabels } from '@/constants/reportScenario'
import { AssetType, MEASURE_BY_ASSET_TYPE } from '@/models/core'

const ScenarioParam = createEnumParam(Object.values(ReportScenario))

export const SCENARIO_INPUT_QUERY_PARAM = {
    type: ScenarioParam,
    name: 'scenario',
}

export const getScenarioOptions = (assetType: AssetType) => {
    const measure = MEASURE_BY_ASSET_TYPE.get(assetType) ?? AssetType.MULTIFAMILY
    return Object.entries(getScenarioLabels(measure)).map(([value, label]) => ({ value, label }))
}

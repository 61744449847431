import cn from 'classnames'

import { Text } from '@/components/base'
import { InputBox } from '@/components/containers'

import styles from './ButtonGroup.module.scss'
import { ButtonGroupProps } from './ButtonGroup.types'

export const ButtonGroup = (props: ButtonGroupProps) => {
    const { className, options = [], selected, onSelect, label } = props

    const buttonElements = options.map(option => {
        const isActive = option.key === selected
        const buttonClasses = cn(styles.button, { [styles.active]: isActive })

        const onClick = () => {
            if (isActive) {
                return
            }
            onSelect?.(option.key)
        }

        return (
            <div
                className={buttonClasses}
                onClick={onClick}
                key={option.key}
                data-testid={`option-${option.key}`}
                role='button'
            >
                <Text
                    text={option.title}
                    variant='smallTextMediumDefault'
                    color={isActive ? 'primaryPurple' : 'secondaryBlack'}
                />
            </div>
        )
    })

    return (
        <InputBox label={label} labelsOnly>
            <div className={cn(styles.buttonGroup, className)}>
                {buttonElements}
            </div>
        </InputBox>
    )
}

import { useEffect, useState } from 'react'

import { Module } from '@ag-grid-community/core'

export const useTableModules = () => {
    const [tableModules, setTableModules] = useState<Promise<Module[]>>(new Promise(() => []))

    useEffect(() => {
        (async () => {
            const modules = Promise.all([
                (await import('@ag-grid-community/client-side-row-model')).ClientSideRowModelModule,
                (await import('@ag-grid-enterprise/status-bar')).StatusBarModule,
                (await import('@ag-grid-enterprise/row-grouping')).RowGroupingModule,
                (await import('@ag-grid-enterprise/set-filter')).SetFilterModule,
                (await import('@ag-grid-enterprise/menu')).MenuModule,
                (await import('@ag-grid-community/csv-export')).CsvExportModule,
                (await import('@ag-grid-enterprise/excel-export')).ExcelExportModule,
            ])
            setTableModules(modules)
        })()
    }, [])

    return tableModules
}

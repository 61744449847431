import { Model, model, field } from 'mobx-orm'

import { api } from '../../http-adapter'

export type ReportTypeName = 'trial_balance' | 'rent_roll' | 'office_rent_roll' | 'budget'

export const reportTypeTitles: Record<ReportTypeName, string> = {
    trial_balance:    'Trial Balance',
    budget:           'Budget',
    rent_roll:        'Rent Roll',
    office_rent_roll: 'Office Rent Roll',
}

@api('csv-report-type')
@model
export class ReportType extends Model {
    @field readonly name?: ReportTypeName
}

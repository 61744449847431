import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface SourceAccountItem {
    asset_id: number
    code: string
    name: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchSourceAccountQueryParams {}

export const SOURCE_ACCOUNT_URL = 'source-account'

export const {
    useItemsQuery: useSourceAccountQuery,
    useItemUpdateMutation: useSourceAccountUpdateMutation,
    useItemsCountQuery: useSourceAccountCountQuery,
    useItemCreateMutation: useSourceAccountCreateMutation,
    useItemDeleteMutation: useSourceAccountDeleteMutation,
} = getRequestQueries<SourceAccountItem, FetchSourceAccountQueryParams>({
    url: SOURCE_ACCOUNT_URL,
})

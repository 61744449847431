import React from 'react'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import App from '@/App'
import { ErrorBoundary } from '@/components/base'
import { AuthContainer, AuthContext, MeContext, PostAuthAppPreparations } from '@/components/containers'
import { sentryInit } from '@/core/sentry'
import StoreProvider from '@/hooks/useStore'
import '@/styles/overrides/velt.scss'

import './index.scss'
import reportWebVitals from './reportWebVitals'

sentryInit()

const enableMocking = async () => {
    if (process.env.NODE_ENV === 'development' && process.env.DEV_API_MOCKS === 'true') {
        const { worker } = await import('@/mocks/browser')
        return worker.start({ onUnhandledRequest: 'bypass' })
    }
    return Promise.resolve()
}

// Prefetch modules list (loading after main bundle)
import(/* webpackPrefetch: true */ '@ag-grid-community/core')
import(/* webpackPrefetch: true */ '@ag-grid-community/csv-export')
import(/* webpackPrefetch: true */ '@ag-grid-community/client-side-row-model')
import(/* webpackPrefetch: true */ '@ag-grid-enterprise/core')
import(/* webpackPrefetch: true */ '@ag-grid-enterprise/status-bar')
import(/* webpackPrefetch: true */ '@ag-grid-enterprise/row-grouping')
import(/* webpackPrefetch: true */ '@ag-grid-enterprise/set-filter')
import(/* webpackPrefetch: true */ '@ag-grid-enterprise/column-tool-panel')
import(/* webpackPrefetch: true */ '@ag-grid-enterprise/menu')
import(/* webpackPrefetch: true */ '@ag-grid-enterprise/excel-export')

import('@ag-grid-enterprise/core').then((data) => {
    data.LicenseManager
        // .setLicenseKey('CompanyName=Intelas, Inc.,LicensedApplication=Intelas,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=1,AssetReference=AG-029783,SupportServicesEnd=28_June_2023_[v2]_MTY4NzkwNjgwMDAwMA==ec442e144449470397bf10f9bb5a7b5e')
        // should we convert it to prev format?
        .setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-061326}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Intelas,_Inc.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Intelas}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Intelas}_need_to_be_licensed___{Intelas}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{27_June_2025}____[v3]_[01]_MTc1MDk3ODgwMDAwMA==2cf4c68756c1b6b4d4903fb45f94d831')
})

const container = document.getElementById('root')
// @ts-expect-error TS(2345) FIXME: Argument of type 'HTMLElement | null' is not assig... Remove this comment to see the full error message
const root = createRoot(container)

export const queryClient = new QueryClient()

enableMocking().then(() => {
    root.render(
        <StoreProvider>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <QueryParamProvider adapter={ReactRouter6Adapter}>
                        <ErrorBoundary level='top'>
                            <AuthContext>
                                <AuthContainer>
                                    <MeContext>
                                        <PostAuthAppPreparations>
                                            <App/>
                                        </PostAuthAppPreparations>
                                    </MeContext>
                                </AuthContainer>
                            </AuthContext>
                        </ErrorBoundary>
                    </QueryParamProvider>
                </BrowserRouter>
            </QueryClientProvider>
        </StoreProvider>,
    )
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// Check if HMR interface is enabled
if (module.hot) {
    // Accept hot update
    module.hot.accept()
}

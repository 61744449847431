import { useEffect, useState } from 'react'

import { Tooltip } from '@mui/material'
import format from 'date-fns/format'
import { EQ } from 'mobx-orm'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router'

import { useAssetsQuery } from '@/api/asset/asset'
import { useCompanyQuery } from '@/api/company/company'
import { useDataPointErrorQuery } from '@/api/dataPulling/dataPointError'
import { useDataSubmissionByIdQuery } from '@/api/dataPulling/dataSubmission'
import { useDataSubmissionErrorQuery } from '@/api/dataPulling/dataSubmissionError'
import { useCompanyUserQuery } from '@/api/user/companyUser'
import { Alert, Button, Icon, SideModal, Text } from '@/components/base'
import { AgGridTableProps } from '@/components/legacy/tables/AgGridTableLegacy'
import {
    ASSET_SIDE_MODAL_INPUT_QUERY_PARAM,
    ASSET_TYPE_SELECT_INPUT_QUERY_PARAM,
} from '@/components/models/asset'
import { LEDGER_SELECT_INPUT_QUERY_PARAM } from '@/components/models/ledger'
import { ReactQueryTable } from '@/components/reactQuery'
import { STRING_COMPARATOR } from '@/components/tables/AgGridTable/AgGridTable.constants'
import { useMe } from '@/hooks/core/useMe'
import http from '@/http.service'
import { SubmittedFileStatus, SubmittedFileType } from '@/models/data_submission'
import { DataSubmissionInternalErrorMessage } from '@/pages/MainPage/CommonPages/DataSubmissionPage'
import {
    FileErrosSideModalProps,
} from '@/pages/MainPage/CommonPages/DataSubmissionPage/DataSubmissionUploadsPage/FileErrorsSideModal/FileErrosSideModal.types'
import {
    UNMAPPED_ONLY_QUERY_PARAM,
} from '@/pages/MainPage/UserPages/MappingPage/MappingRentRollPage/MappingRentRollPage.constants'
import { normalizeShortBackDate } from '@/utils/date/normalizeShortBackDate'
import { userFullname } from '@/utils/mobx-orm/userFullname'
import { getRoute } from '@/utils/routing/getRoute'

import styles from './FileErrorsSideModal.module.scss'

/**
 * Used by admin page and legacy data submission page
 * Newer one (FileErrorsDataPointSideModal) used data point only
 */
const FileErrorsSideModal = observer((props: FileErrosSideModalProps) => {
    const { closePathKey } = props

    const { me } = useMe()

    const { submittedFileId } = useParams()

    const navigate = useNavigate()

    const companyUserQuery = useCompanyUserQuery()
    const assetsQuery = useAssetsQuery()
    const companyQuery = useCompanyQuery()

    const { data: submittedFile } = useDataSubmissionByIdQuery(submittedFileId)

    const useDataPoint = !!submittedFile?.data_point_id

    const dataSubmissionErrorQuery = useDataSubmissionErrorQuery({
        filter: EQ('submitted_file_id', submittedFile?.id),
    }, {
        enabled: !useDataPoint && !!submittedFile?.id,
    })

    const dataPointErrorQuery = useDataPointErrorQuery({
        filter: EQ('data_point_id', submittedFile?.data_point_id),
    }, {
        enabled: useDataPoint,
    })

    const errorsQuery = useDataPoint ? dataPointErrorQuery : dataSubmissionErrorQuery

    const submissionErrorsNum = submittedFile?.submission_errors_num
    const qualityErrorsNum = submittedFile?.quality_errors_num

    let tableTitle: string | null = null
    let titleHint: string | null = null

    if (submittedFile?.status === SubmittedFileStatus.ERROR) {
        tableTitle = 'Submission Errors (1)'
    } else if (submissionErrorsNum) {
        tableTitle = `Submission Errors (${submissionErrorsNum})`
        titleHint = 'Helps identify reasons for failed submissions. Possible errors include incorrect file format, invalid asset name, invalid characters, or unrecognized data. All submission errors must be resolved before data is accepted by the system.'
    } else if (qualityErrorsNum) {
        tableTitle = `Data Quality Alerts (${qualityErrorsNum})`
        titleHint = 'Data quality alerts identify potential irregularities in the submitted data set. Data has been accepted by the system, but we encourage you to review the list of alerts and determine if any changes need to be made to the underlying data.'
    }

    const [unmappedLedgersIds, setUnmappedLedgersIds] = useState([])
    const loadUnmappedLedgersIds = async () => {
        if (submittedFile?.data_point_id) {
            const urlParams = new URLSearchParams({ dataPointId: submittedFile?.data_point_id.toString() })
            // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            const response = await http.get(
                `data-pulling/data-point-unmapped-ledgers?${urlParams}`,
                { headers: me.isAdministratorMode ? {} : { 'company-user-id': `${me.companyUser.id}` } },
            )
            setUnmappedLedgersIds(response.data.ledgers_ids)
        }
    }

    useEffect(() => {
        loadUnmappedLedgersIds()
    }, [errorsQuery.data])

    const closePath = `${getRoute(closePathKey)}?${new URLSearchParams(window.location.search)}`

    if (!submittedFile) {
        return (
            <SideModal
                closePath={closePath}
                width={700}
                haveCloseButton
                title='Submitted file details is loading'
                isLoading
            />
        )
    }

    const navigateToMappingPage = (ledger_id) => {
        const isTrialBalanceType = submittedFile.type === SubmittedFileType.TRIAL_BALANCE_DATA
        const routeName = isTrialBalanceType ? 'ASSET_MAPPING_TRIAL_BALANCE' : 'ASSET_MAPPING_RENT_ROLL'

        const assetType = assetsQuery.data?.find((asset) => asset.id === submittedFile.asset_id)?.type || ''

        const url = getRoute(routeName, {}, {
            [ASSET_SIDE_MODAL_INPUT_QUERY_PARAM.name]: String(submittedFile.asset_id),
            [ASSET_TYPE_SELECT_INPUT_QUERY_PARAM.name]: assetType,
            [LEDGER_SELECT_INPUT_QUERY_PARAM.name]: String(ledger_id),
            [UNMAPPED_ONLY_QUERY_PARAM.name]: 1,
        })

        navigate(url)
    }

    const fileCompanyUser = companyUserQuery.data?.find((companyUser) => companyUser.id === submittedFile.company_user_id)
    const fileAsset = assetsQuery.data?.find((asset) => asset.id === submittedFile.asset_id)
    const fileCompany = companyQuery.data?.find((companyUser) => companyUser.id === submittedFile.company_user_id)

    return (
        <SideModal
            closePath={closePath}
            width={700}
            haveCloseButton
            title={submittedFile.initial_file_name}
            titleComponent={(
                <div className={styles.head}>
                    <div className={styles.headRow}>
                        <div className={styles.headRowCell}>
                            <div className={styles.headRowCellTitle}>
                                <Text variant='smallTextMediumDefault' color='secondaryLightBlack'>Report date:</Text>
                            </div>
                            <div className={styles.headRowCellValue}>
                                <Text variant='smallTextSemiboldDefault' color='secondaryBlack'>
                                    {!submittedFile.report_date && '—'}
                                    {submittedFile.report_date && submittedFile.type === SubmittedFileType.RENT_ROLL_DATA &&
                                        format(normalizeShortBackDate(submittedFile.report_date), 'MMM d yyyy')}
                                    {submittedFile.report_date && submittedFile.type !== SubmittedFileType.RENT_ROLL_DATA &&
                                        format(normalizeShortBackDate(submittedFile.report_date), 'MMM yyyy')}
                                </Text>
                            </div>
                        </div>
                        <div className={styles.headRowCell}>
                            <div className={styles.headRowCellTitle}>
                                <Text variant='smallTextMediumDefault' color='secondaryLightBlack'>Uploaded At:</Text>
                            </div>
                            <div className={styles.headRowCellValue}>
                                <Text variant='smallTextSemiboldDefault' color='secondaryBlack'>
                                    {submittedFile.created_at && format(new Date(submittedFile.created_at), 'MMM d yyyy, h:mm a')}
                                </Text>
                            </div>
                        </div>
                    </div>
                    <div className={styles.headRow}>
                        <div className={styles.headRowCell}>
                            <div className={styles.headRowCellTitle}>
                                <Text variant='smallTextMediumDefault' color='secondaryLightBlack'>Asset:</Text>
                            </div>
                            <div className={styles.headRowCellValue}>
                                <Text
                                    variant='smallTextSemiboldDefault'
                                    color='secondaryBlack'
                                >{fileAsset?.name || '—'}</Text>
                            </div>
                        </div>
                        <div className={styles.headRowCell}>
                            <div className={styles.headRowCellTitle}>
                                <Text variant='smallTextMediumDefault' color='secondaryLightBlack'>Uploaded By:</Text>
                            </div>
                            <div className={styles.headRowCellValue}>
                                <Text variant='smallTextSemiboldDefault' color='secondaryBlack'>
                                    {
                                        fileCompanyUser
                                            ? userFullname(fileCompanyUser)
                                            : 'Intelas'
                                    }
                                </Text>
                            </div>
                        </div>
                    </div>
                    <div className={styles.headRow}>
                        <div className={styles.headRowCell}>
                            <div className={styles.headRowCellTitle}>
                                <Text variant='smallTextMediumDefault' color='secondaryLightBlack'>Company:</Text>
                            </div>
                            <div className={styles.headRowCellValue}>
                                <Text variant='smallTextSemiboldDefault' color='secondaryBlack'>
                                    {
                                        fileCompany
                                            ? fileCompany.name
                                            : 'Intelas'
                                    }
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        >
            <div className={styles.modalContent}>
                <div className={styles.contentTopPane}>
                    {
                        titleHint && (
                            <Tooltip
                                title={titleHint} arrow
                                placement='top-end'
                            >
                                <span className={styles.infoIconContainer}>
                                    <Icon
                                        name='info' color='colorsSecondaryPurple700'
                                        size='l'
                                    />
                                </span>
                            </Tooltip>
                        )
                    }
                    <Text
                        block
                        text={tableTitle}
                        color='secondaryBlack'
                        variant='smallTextSemiboldDefault'
                    />
                    <div className={styles.sep}/>
                    {
                        ((submittedFile.status !== SubmittedFileStatus.ERROR) && !!unmappedLedgersIds.length) && (
                            <Button
                                text='Update Mappings'
                                theme='alert'
                                onClick={() => navigateToMappingPage(unmappedLedgersIds[0])}
                            />
                        )
                    }
                </div>
                {submittedFile.status === SubmittedFileStatus.ERROR && (
                    <Alert
                        content={<DataSubmissionInternalErrorMessage/>}
                        type='error'
                        flexGrow={0}
                    />
                )}
                {((submittedFile.status !== SubmittedFileStatus.ERROR) && Boolean(submissionErrorsNum || qualityErrorsNum)) && (
                    <ReactQueryTable
                        query={errorsQuery}
                        hideStatusBar
                        {...gridOptions}
                    />
                )}
            </div>
        </SideModal>
    )
})

const gridOptions: Omit<AgGridTableProps, 'query' | 'items'> = {
    context: {},
    columnTypes: {
        place: { width: 100 },
        error_text: {
            width: 900,
            cellRenderer: (params) => {
                return <div className={styles.errorTextCell}>{params.data.error_text}</div>
            },
        },
    },
    defaultColDef: {
        resizable: false,
        suppressHeaderMenuButton: true,
        wrapText: true,
        autoHeight: true,
        comparator: STRING_COMPARATOR,
    },
    columnDefs: [
        { field: 'place', headerName: 'Place', type: 'place', width: 100 },
        { field: 'error_text', headerName: 'Error', type: 'error_text' },
    ],
}

export default FileErrorsSideModal

import { useMemo, useState } from 'react'

import format from 'date-fns/format'
import { Query } from 'mobx-orm'
import { observer } from 'mobx-react-lite'

import { Filters, FiltersInput, LegacySelectItemData } from '@/components/base'
import { useMe } from '@/hooks/core/useMe'
import { useQueryParam } from '@/hooks/useQueryParams'
import { Company } from '@/models/company'
import { DataPointType } from '@/models/data_pulling'
import { DataPointErrorCode } from '@/models/data_pulling/DataPointErrorCode'
import {
    getMonthsFromBeginning,
    getYearsFromBeginning,
    ValidationTrackerFiltersState,
} from '@/pages/MainPage/CommonPages/ValidationTrackerPage'

import { ValidationTrackerFiltersProps } from './ValidationTrackerFilters.types'

export const ValidationTrackerFilters = observer((props: ValidationTrackerFiltersProps) => {
    const { assetQuery } = props

    const { me } = useMe()
    const [companyQuery] = useState(() => Company.getQuery() as Query<Company>)
    const [errorCodeQuery] = useState(() => DataPointErrorCode.getQuery() as Query<DataPointErrorCode>)

    const [params, updateParam] = useQueryParam<ValidationTrackerFiltersState>()

    // @ts-expect-error TS(2345) FIXME: Argument of type '() => { label: string | undefine... Remove this comment to see the full error message
    const companyOptions = useMemo<LegacySelectItemData[]>(() => {
        const mainList = companyQuery.items.map((item) => (
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            { label: item.name, value: item.id.toString() }
        ))

        return [{ label: 'All Companies', value: '0' }, ...mainList]
    }, [companyQuery.items])

    // @ts-expect-error TS(2345) FIXME: Argument of type '() => { label: string | undefine... Remove this comment to see the full error message
    const assetOptions = useMemo<LegacySelectItemData[]>(() => {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        const mainList = assetQuery.items.map((item) => (
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            { label: item.name, value: item.id.toString() }
        ))

        return [{ label: 'All Assets', value: '0' }, ...mainList]
    }, [assetQuery?.items])

    // @ts-expect-error TS(2345) FIXME: Argument of type '() => { label: string | undefine... Remove this comment to see the full error message
    const statusOptions = useMemo<LegacySelectItemData[]>(() => {
        const alertsList = errorCodeQuery.items
            .filter(item => item.type === 'alert')
            .filter(item => item.user_error_name !== 'Internal Error')
            .map((item) => (
                { label: item.user_error_name, value: item.code.toString() }
            ))

        return [{ label: 'All', value: 'all' }, { label: 'Errors', value: 'error' }, ...alertsList]
    }, [errorCodeQuery.items])

    const requiredInputsConfig: FiltersInput[] = [
        {
            type: 'select',
            name: 'companyId',
            hidden: !!me.company.id,
            props: {
                labelInside: true,
                label: 'Company',
                placeholder: 'Company',
                customValue: params.companyId?.toString(),
                options: companyOptions,
                onChange: () => {
                    updateParam({ key: 'assetId', val: '0' })
                },
            },
        },
        {
            type: 'select',
            name: 'assetId',
            props: {
                labelInside: true,
                label: 'Asset',
                placeholder: 'Asset',
                customValue: params.assetId?.toString(),
                options: assetOptions,
            },
        },
    ]

    const fileTypeOptions = useMemo<LegacySelectItemData[]>(() => {
        return [
            { label: 'All', value: 'all' },
            { label: 'Trial Balance', value: DataPointType.TRIAL_BALANCE },
            { label: 'Rent Roll', value: DataPointType.RENT_ROLL },
        ]
    }, [])

    const propertyManangerOptions = useMemo<LegacySelectItemData[]>(() => {
        if (params.assetId !== '0') {
            return []
        }
        const managerCompanies = Array.from(new Set((assetQuery?.items || []).map((item) => item.property_manager_company))).filter((el): el is string => Boolean(el))
        if (managerCompanies.length === 0) {
            return []
        }
        return [
            { label: 'All', value: '' },
            ...managerCompanies.map(el => ({ label: el, value: el })),
        ]
    }, [params.assetId, assetQuery?.items])

    const filtersConfig: FiltersInput[] = [
        {
            type: 'select',
            name: 'fileType',
            props: {
                label: 'Report Type',
                placeholder: 'Report Type',
                customValue: params.fileType?.toString(),
                options: fileTypeOptions,
            },
        },
        {
            type: 'buttonsGroup',
            name: 'periodType',
            props: {
                label: 'Interval',
                options: [
                    { title: 'Daily', key: 'daily' },
                    { title: 'Monthly', key: 'monthly' },
                ],
                selected: params.periodType,
            },
        },
        {
            type: 'select',
            name: 'currentYear',
            hidden: params.periodType !== 'monthly',
            props: {
                label: 'Period',
                placeholder: 'Year',
                customValue: params.currentYear?.toString(),
                options: getYearsFromBeginning().reverse().map((date) => (
                    { value: date.getFullYear().toString(), label: date.getFullYear().toString() }
                )),
            },
        },
        {
            type: 'select',
            hidden: params.periodType !== 'daily',
            props: {
                label: 'Period',
                placeholder: 'Month',
                customValue: `${params.currentYear}-${params.currentMonth}`,
                options: getMonthsFromBeginning().map((date: Date) => (
                    { value: `${date.getFullYear()}-${date.getMonth() + 1}`, label: format(date, 'MMMM yyyy') }
                )),
                multiSelect: false,
                onChange: (option: { label: string, value: string }) => {
                    updateParam(
                        { key: 'currentMonth', val: Number(option.value.split('-')[1]) },
                        { key: 'currentYear', val: Number(option.value.split('-')[0]) },
                    )
                },
            },
        },
        {
            type: 'select',
            props: {
                label: 'Status',
                placeholder: 'Status',
                customValue: params.errorCode === '0' ? params.statusType : params.errorCode,
                options: statusOptions,
                onChange: (option: { label: string, value: string }) => {
                    const intValue = parseInt(option.value)
                    const isErrorCodeSelected = !isNaN(intValue)
                    updateParam(
                        { key: 'statusType', val: !isErrorCodeSelected ? option.value : 'all' },
                        { key: 'errorCode', val: isErrorCodeSelected ? intValue : 0 },
                    )
                },
            },
        },
        {
            type: 'select',
            hidden: !propertyManangerOptions.length,
            props: {
                label: 'Property Manager',
                placeholder: 'Property Manager',
                customValue: params.propertyManager,
                options: propertyManangerOptions,
                onChange: (option: { label: string, value: string }) => {
                    updateParam(
                        { key: 'propertyManager', val: option.value },
                    )
                },
            },
        },
    ]

    return (
        <>
            <Filters
                inputs={requiredInputsConfig}
                required
            />
            <Filters
                inputs={filtersConfig}
                hideTitle
            />
        </>
    )
})

import { ReportColumnAggregation } from '@/constants/reportColumnAggregation'

/* eslint-disable no-multi-spaces, @typescript-eslint/type-annotation-spacing, import/order */
export enum AssetType {
    MULTIFAMILY  = 'M',
    OFFICE       = 'O',
    INDUSTRIAL   = 'I',
    RETAIL       = 'R',
    HOSTPITALITY = 'H',
}

export const ASSET_TYPE_LABEL  = new Map<AssetType, string>([
    [AssetType.MULTIFAMILY,  'Multifamily'],
    [AssetType.OFFICE,       'Office'],
    [AssetType.INDUSTRIAL,   'Industrial'],
    [AssetType.RETAIL,       'Retail'],
    [AssetType.HOSTPITALITY, 'Hostpitality'],
])

export const MEASURE_BY_ASSET_TYPE = new Map<AssetType, string>([
    [AssetType.MULTIFAMILY,  '/Unit'],
    [AssetType.OFFICE,       'PSF'],
    [AssetType.INDUSTRIAL,   'PSF'],
    [AssetType.RETAIL,       'PSF'],
    [AssetType.HOSTPITALITY, '/Unit'],
])

export const AGGREGATION_BY_ASSET_TYPE = new Map<AssetType, `${ReportColumnAggregation}`>([
    [AssetType.MULTIFAMILY,  'u_avg'],
    [AssetType.OFFICE,       'sf_avg'],
    [AssetType.INDUSTRIAL,   'sf_avg'],
    [AssetType.RETAIL,       'sf_avg'],
    [AssetType.HOSTPITALITY, 'u_avg'],
])

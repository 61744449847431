import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { BuiltInSearchInput, Button, Icon, Line, SelectOptionDefault } from '@/components/base'
import popoverStyles from '@/components/base/Popover/Popover.module.scss'
import { ListHeader } from '@/components/base/Select/headers'
import { SelectOptionListBase } from '@/components/base/Select/optionsList'
import { Layout, SideModalContainer } from '@/components/containers'
import {
    MAPPING_ENGINE_ONE_MODULE_WIDTH,
} from '@/components/widgets/MappingEngineSideModal/MappingEngineSideModal.constants'
import { generatedColors } from '@/styles/tokens/Tokens.constants'

import { SideModalOptionsProps } from './SideModalOptions.types'

export const SideModalOptions = <Option = SelectOptionDefault>(props: SideModalOptionsProps<Option>) => {
    const {
        options,
        selected,
        onClose,
        onSave,
        multiSelect = false,
        required = false,
        labelFn,
        idFn,
    } = props

    const [filterValue, setFilterValue] = useState<string>('')
    const [selectedOptions, setSelectedOptions] = useState<Option[]>(selected)

    useEffect(() => setSelectedOptions(selected), [selected])

    const isSelectionChanged = useMemo(
        () => {
            const getIdsAsString = (options: Option[]) => options.map(idFn).sort().join(',')
            return getIdsAsString(selectedOptions) !== getIdsAsString(selected)
        },
        [selectedOptions, selected, idFn],
    )

    const headerText = useMemo(
        () => multiSelect
            ? selectedOptions.length
                ? `${selectedOptions.length} item(s) selected` : 'Select 1 or more item(s)'
            : 'Select an item',
        [multiSelect, selectedOptions.length],
    )

    const filteredOptions = useMemo(
        () => {
            const filterRegex = new RegExp(filterValue, 'i')
            return options.filter((option) => !filterValue || filterRegex.test(labelFn(option)))
        },
        [filterValue, labelFn, options],
    )

    const handleSelectOption = useCallback((clickedOption: Option) => {
        const isSelected = selectedOptions.some((option) => idFn(option) === idFn(clickedOption))

        if (multiSelect) {
            const newSelected = isSelected
                ? selectedOptions.filter((option) => idFn(option) !== idFn(clickedOption))
                : [...selectedOptions, clickedOption]

            setSelectedOptions(newSelected)
        } else {
            setSelectedOptions(isSelected ? [] : [clickedOption])
        }
    }, [idFn, multiSelect, selectedOptions])

    const handleSelectAll = useCallback(() => setSelectedOptions(options), [options])
    const handleClearAll = useCallback(() => setSelectedOptions([]), [])

    return (
        <SideModalContainer onBgClick={onClose}>
            <Layout
                gap={4}
                my={4}
                mr={4}
            >
                <Layout
                    className={popoverStyles.popover}
                    direction='column'
                    width={MAPPING_ENGINE_ONE_MODULE_WIDTH}
                    relative
                >
                    <ListHeader
                        content={[headerText]}
                        endContent={(
                            <Layout gap={8}>
                                <Icon
                                    name='cross'
                                    onClick={onClose}
                                    color='colorsSecondaryGrey600'
                                />
                            </Layout>
                        )}
                    />
                    <Layout py={4}>
                        <BuiltInSearchInput
                            onChange={(value) => setFilterValue(value)}
                            value={filterValue}
                            placeholder='Search'
                            dataTestId='search'
                        />
                    </Layout>
                    {multiSelect && (
                        <>
                            <Layout
                                style={{ backgroundColor: generatedColors.colorsSecondaryPurple25 }}
                                px={12}
                                py={8}
                                gap={12}
                                justify='space-between'
                            >
                                <Button
                                    theme='flat' text='Select All'
                                    onClick={handleSelectAll}
                                    dataTestId='selectAll'
                                />
                                <Button
                                    theme='flat' text='Clear All'
                                    onClick={handleClearAll}
                                    dataTestId='clearAll'
                                />
                            </Layout>
                        </>
                    )}
                    <Layout flexGrow={1} scroll='y' minHeight='200px'>
                        <SelectOptionListBase<Option>
                            width='100%'
                            options={filteredOptions}
                            selected={selectedOptions}
                            labelFn={labelFn}
                            idFn={idFn}
                            onChange={handleSelectOption}
                        />
                    </Layout>
                    <Line/>
                    <Layout px={12} py={8} justify='space-between'>
                        <Button
                            text='Reset'
                            theme='flat'
                            disabled={!isSelectionChanged}
                            onClick={() => setSelectedOptions(selected)}
                        />
                        <Layout flexGrow={1}/>
                        <Button
                            disabled={selectedOptions.length === 0 && required}
                            text='Apply'
                            onClick={() => onSave(selectedOptions)}
                        />
                    </Layout>
                </Layout>
            </Layout>
        </SideModalContainer>
    )
}

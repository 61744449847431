import React, { useEffect, useState } from 'react'

import { AND, EQ, IN } from 'mobx-orm'
import { StringParam, useQueryParams } from 'use-query-params'

import { useTrialBalanceMapQuery } from '@/api/trialBalance/trialBalanceMap'
import popoverStyles from '@/components/base/Popover/Popover.module.scss'
import { SelectOptionBase } from '@/components/base/Select/options'
import { SelectOptionListBase } from '@/components/base/Select/optionsList'
import { Text } from '@/components/base/Text'
import { Layout } from '@/components/containers'
import {
    MAPPING_ENGINE_ONE_MODULE_WIDTH,
} from '@/components/widgets/MappingEngineSideModal/MappingEngineSideModal.constants'
import { getAccountItemLabel } from '@/components/widgets/MappingEngineSideModal/MappingEngineSideModal.utils'
import {
    assetTypeQueryParam,
} from '@/components/widgets/mappings/MappingTrialBalance/MappingTrialBalance.constants'
import { TrialBalanceMap } from '@/models/ledger'

import { MappingEngineSourceAccountsProps } from './MappingEngineSourceAccounts.types'

export const MappingEngineSourceAccounts = (props: MappingEngineSourceAccountsProps) => {
    const { ledgerData, selectedAccount } = props

    const [{ [assetTypeQueryParam]: assetType }] = useQueryParams({
        [assetTypeQueryParam]: StringParam,
    })

    const [alreadyMappedAccounts, setAlreadyMappedAccounts] = useState<TrialBalanceMap[]>([])

    const trialBalanceMapQuery = useTrialBalanceMapQuery({
        filter: AND(
            IN('type', assetType ? [assetType] : []),
            EQ('custom_ledger_id', ledgerData?.id),
            IN('custom_account_ids', selectedAccount?.id ? [selectedAccount.id] : []),
        ),
    })

    useEffect(() => {
        const normalizedItems = (trialBalanceMapQuery.data || [])
            // Sorting by code
            .sort((a, b) => {
                if (!a.code || !b.code) return 0

                const codeA = Number(a.code.replace(/[^0-9]/g, ''))
                const codeB = Number(b.code.replace(/[^0-9]/g, ''))

                return codeA - codeB
            })
            // Removing duplicates
            .reduce<TrialBalanceMap[]>((acc, current) => {
            if (acc.length === 0 || acc[acc.length - 1].code !== current.code) {
                acc.push(current)
            }
            return acc
        }, [])

        setAlreadyMappedAccounts(normalizedItems)
    }, [trialBalanceMapQuery.data])

    if (!ledgerData) return null

    let headerText = `Source Accounts Mapped to Target Account:
    ${getAccountItemLabel(selectedAccount) || '—'}`

    if (alreadyMappedAccounts.length === 0 && trialBalanceMapQuery.isFetched) {
        headerText = `There is no Source Accounts Mapped to Target Account:
        ${getAccountItemLabel(selectedAccount) || '—'}`
    }

    if (!selectedAccount?.id) {
        headerText = 'Select a target account to see here what other source account(s) already mapped to it'
    }

    if (!trialBalanceMapQuery.isFetched) {
        headerText = ''
    }

    return (
        <Layout
            className={popoverStyles.popover}
            width={MAPPING_ENGINE_ONE_MODULE_WIDTH}
            py={8}
            direction='column'
        >
            <Layout
                mb={8} px={12}
            >
                <Text
                    variant='smallTextSemiboldDefault'
                    color='colorsPrimaryGrey'
                    text={headerText}
                />
            </Layout>

            <Layout flexGrow={1} scroll='y'>
                <SelectOptionListBase<TrialBalanceMap>
                    loading={!trialBalanceMapQuery.isFetched}
                    width='100%'
                    optionComponent={SelectOptionBase}
                    options={selectedAccount ? alreadyMappedAccounts : []}
                    labelFn={(item) => getAccountItemLabel(item)}
                    idFn={(account) => account?.id?.toString() || null}
                    noItemsPlaceholder=''
                    disableAll
                    onChange={() => {}}
                />
            </Layout>
        </Layout>
    )
}

import { AgGridReactProps } from '@ag-grid-community/react'

export const gridOptions: Partial<AgGridReactProps> = {
    columnDefs: [
        // Switch cell will be pushed here in JSX
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 200,
            type: 'string',
        },
        {
            field: 'software',
            minWidth: 200,
            headerName: 'Software',
            type: 'string',
        },
    ],
}

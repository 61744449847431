import { Auth0ProviderOptions } from '@auth0/auth0-react'
import { NavigateFunction } from 'react-router/dist/lib/hooks'

export const getAuthConfig = (navigate: NavigateFunction): Auth0ProviderOptions => ({
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    domain: process.env.MFA_DOMAIN,
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    clientId: process.env.MFA_CLIENT_ID,
    // Avoid redirect to auth0 in dev env
    useRefreshTokens: process.env.NODE_ENV !== 'development',
    authorizationParams: {
        redirect_uri: window.location.origin,
        audience: process.env.MFA_AUDIENCE,
    },
    onRedirectCallback: (appState) => {
        navigate(appState?.returnTo || window.location.pathname)
    },
})

/**
 * Dev version of build
 */
export const isDevMode = process.env.NODE_ENV === 'development'

import { ReportingPortfolioSelectedValueRow } from '@/api/reportingData/reportingPortfolioSelectedValue'

/* eslint-disable @typescript-eslint/naming-convention */
export enum AssetDetails {
    CITY = 'c',
    STATE = 's',
    ASSET_CLASS = 'ac',
    MANAGER = 'm',
    FUND = 'f',
    UNITS = 'u',
    SQUARE_FEET = 'sf',
}
export const ASSET_DETAIL_LABELS: Record<AssetDetails, string> = {
    [AssetDetails.CITY]: 'City',
    [AssetDetails.STATE]: 'State',
    [AssetDetails.ASSET_CLASS]: 'Asset Class',
    [AssetDetails.MANAGER]: 'Manager',
    [AssetDetails.FUND]: 'Fund',
    [AssetDetails.UNITS]: 'Units',
    [AssetDetails.SQUARE_FEET]: 'Square Feet',
}

export const ASSET_DETAIL_ID_TO_FIELD: Record<AssetDetails, keyof ReportingPortfolioSelectedValueRow> = {
    [AssetDetails.CITY]: 'city',
    [AssetDetails.STATE]: 'state',
    [AssetDetails.ASSET_CLASS]: 'asset_class',
    [AssetDetails.MANAGER]: 'manager',
    [AssetDetails.FUND]: 'fund',
    [AssetDetails.UNITS]: 'units',
    [AssetDetails.SQUARE_FEET]: 'sqft',
}

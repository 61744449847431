type Quarters = 'q1' | 'q2' | 'q3' | 'q4'

export type QuarterString = `${number}${Quarters}`

export const quarterToDate = (quarterString?: QuarterString) => {
    const match = (quarterString ?? '').match(/^(\d{4})q(\d)$/)
    if (!match) {
        return undefined
    }

    const [, year, q] = match
    const month = (Number(q) - 1) * 3 + 1
    return new Date(`${year}-${month < 10 ? `0${month}` : month}-01T00:00:00.000Z`)
}

export const dateToQuarter = (date?: Date): QuarterString | undefined => {
    // isNaN checks for invalid date
    if (!(date instanceof Date) || isNaN(date as unknown as number)) {
        return undefined
    }

    return `${date.getFullYear()}q${Math.floor(date.getMonth() / 3) + 1}` as QuarterString
}

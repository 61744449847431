import { useCallback, useEffect, useState } from 'react'

import { SelectOptionDefault } from '@/components/base'
import { SelectSelectedItemsBase } from '@/components/base/Select/selectedItems'
import { SelectTriggerBase } from '@/components/base/Select/triggers'
import { SideModalOptions } from '@/components/base/SideModalOptions'
import { useInputState } from '@/hooks'

import { SelectInputWithModalProps } from './SelectInputWithModal.types'

const idFn = (option: SelectOptionDefault) => option?.value?.toString?.() || ''
const labelFn = (option: SelectOptionDefault) => option?.label?.toString?.() || ''

/**
 * Simple select input with modal works with default option type
 * {@link SelectOptionDefault}
 */
export const SelectInputWithModal = (props: SelectInputWithModalProps) => {
    const {
        required = false,
        paramName,
        paramType,
        options = [],
        multiSelect,
        syncLocalStorage,
        label,
        width,
        labelInside,
        placeholder = `Select ${multiSelect ? 'items' : 'item'}`,
        multiSelectPlaceholder,
        defaultActiveOptions = 'none',
    } = props

    const [param, setParam] = useInputState({ name: paramName, type: paramType, syncLocalStorage })
    const [selected, setSelected] = useState<SelectOptionDefault[]>([])
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (!param && defaultActiveOptions === 'first' && options.length) {
            setParam(idFn(options[0]))
        }
        if (!param && defaultActiveOptions === 'all' && options.length) {
            setParam(options.map(option => idFn(option)))
        }
    }, [defaultActiveOptions, options, param, setParam])

    useEffect(() => {
        if (!options?.length) {
            setSelected([])
            return
        }

        const selectedIds = param ? Array.isArray(param) ? param : [param] : []
        const selectedOptions = options.filter((option) => selectedIds.includes(idFn(option)))

        setSelected(selectedOptions)

        // Need this to reset selected value in the case if options are changed and selected value is not in the new options
        if (selectedOptions.length < selectedIds.length) {
            setParam(selectedOptions.map(option => idFn(option)))
        }
    }, [param, options, setParam, multiSelect])

    const handleOnSave = useCallback((options: SelectOptionDefault[]) => {
        setParam(options.map((o) => o.value as string))
        setIsOpen(false)
    }, [setParam])

    return (
        <>
            <SelectTriggerBase
                label={label}
                width={width}
                open={isOpen}
                isActive={isOpen}
                onClick={() => { setIsOpen(true) }}
                error={required && !selected.length}
                labelInside={labelInside}
            >
                <SelectSelectedItemsBase
                    selected={selected}
                    labelFn={labelFn}
                    idFn={idFn}
                    placeholder={placeholder}
                    multiSelectPlaceholder={multiSelectPlaceholder}
                    active={isOpen}
                />
            </SelectTriggerBase>

            {isOpen && (
                <SideModalOptions
                    options={options}
                    selected={selected}
                    onSave={handleOnSave}
                    onClose={() => setIsOpen(false)}
                    multiSelect={multiSelect}
                    required={required}
                    labelFn={labelFn}
                    idFn={idFn}
                />
            )}
        </>
    )
}

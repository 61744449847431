import { useRef, useState } from 'react'

import { Tooltip } from '@mui/material'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { Button, Icon, Text } from '@/components/base'
import { Layout } from '@/components/containers'

import UploadImage from './images/upload.svg'
import styles from './UploadArea.module.scss'
import { UploadFileError, UploadAreaProps } from './UploadArea.types'

const MAX_FILE_SIZE = 128 * 1024 * 1024

export const UploadArea = observer((props: UploadAreaProps) => {
    const {
        className,
        inProgress = false,
        allowedFormats,
        processedCount,
        totalCount,
        onFileSelect,
        onError,
        onCancel,
        hasError = false,
        singleFile = false,
        hideFileConstrains = false,
        fileTypesLabelInside = false,
        verticalLayout = false,
    } = props

    const [isFileInArea, setIsFileInArea] = useState(false)
    const fileRef = useRef<HTMLInputElement>()

    const prepareFiles = (files: FileList) => {
        const errors: UploadFileError[] = []
        const filesArray = Array.from(files)

        const allowedFiled: File[] = filesArray.filter((file: File) => {
            const fileFormat = file.name.split('.').pop()
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            const isFormatAllowed = allowedFormats.includes(fileFormat.toLowerCase())
            const isFileTooBig = file.size > MAX_FILE_SIZE

            if (!isFormatAllowed) {
                errors.push(
                    {
                        fileName: file.name,
                        message: `Unsupported file type: .${fileFormat}`,
                    })
                return false
            } else if (isFileTooBig) {
                errors.push(
                    {
                        fileName: file.name,
                        message: 'File’s size exceeds 128 MB',
                    })
                return false
            } else {
                return true
            }
        })

        if (errors.length) {
            onError({ type: 'file-restriction', errors })
        }
        onFileSelect(allowedFiled)
    }

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setIsFileInArea(false)
        if (!fileRef.current) {
            return
        }

        if (inProgress) {
            onError({ type: 'in-progress' })
            return
        }
        prepareFiles(e.dataTransfer.files)
    }

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
    }

    const handleSelectClick = () => {
        if (inProgress || !fileRef.current) {
            return
        }

        fileRef.current?.click()
    }

    const handleFileSelect = ({ target }) => {
        prepareFiles(target.files)
        target.value = ''
    }

    const uploadAreaClassName = cn(styles.uploadArea, {
        [styles.inProgress]: inProgress,
        [styles.fileOver]: isFileInArea,
        [styles.hasError]: hasError,
        [styles.verticalLayout]: verticalLayout,
    })

    const progressCountMessage = processedCount && totalCount
        ? `${processedCount} of ${totalCount} in Progress. `
        : ''

    const allowedFormatsString = allowedFormats.map(f => `.${f}`).join(' ')
    const title = `Drag & Drop or Select File${singleFile ? '' : '(s)'} to Upload`
    const constrainText = `${singleFile ? '' : '10 File Limit Per Upload, '}128 MB Size Limit Per File`

    return (
        <div className={cn(styles.wrapper, className)}>
            <div
                className={uploadAreaClassName}
                onDragOver={handleDragOver}
                onDragEnter={() => {
                    setIsFileInArea(true)
                }}
                onDragLeave={() => {
                    setIsFileInArea(false)
                }}
                onDrop={handleDrop}
            >
                {inProgress ? (
                    <>
                        <Icon
                            className={styles.loader}
                            name='loader'
                            color='colorsPrimaryPurple'
                            size='l'
                        />
                        <Text
                            className={styles.progressMessage}
                            variant='labelMediumDefault'
                            color='colorsPrimaryPurple'
                            text={`${progressCountMessage}Do Not Leave This Page Until the Processing is Finished`}
                            block
                        />
                        {onCancel && (<Button theme='secondary' text='Cancel' onClick={onCancel}/>)}
                    </>
                ) : (
                    <>
                        <UploadImage className={styles.uploadImage}/>
                        <div className={styles.uploadMessage}>
                            <Text
                                className={styles.uploadMessageTitle}
                                variant='smallTextMediumDefault'
                                color='secondaryBlack'
                                text={title}
                            />
                            {fileTypesLabelInside && (
                                <Text
                                    variant='labelMediumDefault'
                                    color='colorsPrimaryPurple'
                                    text={`Supported file types: ${allowedFormatsString}`}
                                />
                            )}
                            {!hideFileConstrains && (
                                <Text
                                    variant='labelMediumDefault'
                                    color='secondaryGray'
                                    text={constrainText}
                                />
                            )}
                            <Layout mt={16}>
                                <Button
                                    theme='primary'
                                    text={`Select File${singleFile ? '' : 's'}`}
                                    onClick={handleSelectClick}
                                />
                            </Layout>
                        </div>
                    </>
                )}
                <input
                    className={styles.input}
                    type='file'
                    // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<HTMLInputElement | undefine... Remove this comment to see the full error message
                    ref={fileRef}
                    multiple={!singleFile}
                    onChange={handleFileSelect}
                />
            </div>
            {!fileTypesLabelInside && (
                <Layout mt={12}>
                    <Tooltip
                        title={`Supported file types: ${allowedFormatsString}`}
                        placement='top'
                        arrow
                    >
                        <span>
                            <Text
                                className={styles.supportedFilesHint}
                                text='What type of files does Intelas work with?'
                                variant='labelSemiboldDefault'
                                color='colorsPrimaryPurple'
                            />
                        </span>
                    </Tooltip>
                </Layout>
            )}
        </div>
    )
})
